/** @format */

import React from 'react';

class TaskDetail extends React.Component {
	render() {
		const { title, date, dateString, details, category, priority, labels, status } = this.props.details;
		const index = this.props.index;
		const taskId = this.props.taskId;
		return (
			<React.Fragment>
				<div className='mb-2'>
					{Object.keys(this.props.details).map((key, index) => (
						<>
							<h1>
								{this.props.details[key].key == this.props.task && (
									<>
										{this.props.details[key].task.priority === 'high' && (
											<a title='High Priority'>
												<i className='simple-icon-fire heading-icon'></i>
											</a>
										)}
										{this.props.details[key].task.priority === 'medium' && (
											<a title='Medium Priority'>
												<i className='simple-icon-energy heading-icon'></i>
											</a>
										)}
										{this.props.details[key].task.priority === 'low' && (
											<a title='Low Priority'>
												<i className='simple-icon-pin heading-icon'></i>
											</a>
										)}
										<i className='simple-icon-note heading-icon'></i>
										<span className='align-middle d-inline-block pt-1'>{this.props.details[key].task.title}</span>
									</>
								)}
							</h1>
						</>
					))}
				</div>

				<ul className='nav nav-tabs separator-tabs ml-0 mb-5' role='tablist'>
					<li className='nav-item'>
						<a
							className='nav-link active'
							id='first-tab'
							data-toggle='tab'
							href='#first'
							role='tab'
							aria-controls='first'
							aria-selected='true'>
							DETAILS
						</a>
					</li>
					<li class='nav-item'>
						<a
							class='nav-link '
							id='third-tab'
							data-toggle='tab'
							href='#third'
							role='tab'
							aria-controls='third'
							aria-selected='false'>
							CONTRIBUTIONS
						</a>
					</li>
				</ul>
				<div className='tab-content mb-4'>
					<div className='tab-pane show active' id='first' role='tabpanel' aria-labelledby='first-tab'>
						<div className='row'>
							{Object.keys(this.props.details).map((key, index) => (
								<>
									{this.props.details[key].key == this.props.task && (
										<>
											<div className='col-lg-12 col-12 mb-4'>
												<div className='card mb-4'>
													<div className='position-absolute card-top-buttons'>
														<button className='btn btn-header-light icon-button'>
															<i className='simple-icon-pencil'></i>
														</button>
													</div>

													<div className='card-body'>
														<p className='list-item-heading mb-4'>Priority</p>
														<p className='list-item-heading mb-4'>Summary</p>
														<p className='text-muted text-small mb-2'>Info</p>
														<p className='mb-3'>{this.props.details[key].task.details}</p>

														<p className='text-muted text-small mb-2'>Due Date</p>
														<p className='mb-3'>{this.props.details[key].task.dateString}</p>

														<p className='text-muted text-small mb-2'>Labels</p>
														<div>
															{this.props.details[key].task.labels.map((i, index) => {
																return (
																	<>
																		<p className='d-sm-inline-block mb-1'>
																			<a href='#'>
																				<span className='badge badge-pill badge-outline-theme-3 mb-1'>{i}</span>
																			</a>
																		</p>
																	</>
																);
															})}
														</div>
													</div>
												</div>
											</div>
										</>
									)}
								</>
							))}
						</div>
					</div>

					<div className='tab-pane fade' id='third' role='tabpanel' aria-labelledby='third-tab'>
						<div className='row'>
							<div className='col-lg-12 col-12 mb-4'>
								<div className='card mb-4'>
									<div className='card-body'>
										<p className='list-item-heading mb-4'>Overview</p>
										<div className='mb-4'>
											<p className='mb-2'>
												Pull Requests
												<span className='float-right text-muted'>12/18</span>
											</p>
											<div className='progress mb-3'>
												<div
													className='progress-bar'
													role='progressbar'
													aria-valuenow='60'
													aria-valuemin='0'
													aria-valuemax='100'></div>
												<div
													className='progress-bar bg-theme-2'
													role='progressbar'
													aria-valuenow='40'
													aria-valuemin='0'
													aria-valuemax='100'></div>
											</div>

											<table className='table table-sm table-borderless'>
												<tbody>
													<tr>
														<td className='p-0 pb-1 w-10'>
															<span className='log-indicator border-theme-1 align-middle'></span>
														</td>
														<td className='p-0 pb-1'>
															<span className='font-weight-medium text-muted text-small'>3 Merged Requests</span>
														</td>
													</tr>
													<tr>
														<td className='p-0 pb-1 w-10'>
															<span className='log-indicator border-theme-2 align-middle'></span>
														</td>
														<td className='p-0 pb-1'>
															<span className='font-weight-medium text-muted text-small'>2 Proposed Requests</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>

										<div>
											<p className='mb-2'>
												Issues
												<span className='float-right text-muted'>24/32</span>
											</p>
											<div className='progress mb-3'>
												<div
													className='progress-bar'
													role='progressbar'
													aria-valuenow='80'
													aria-valuemin='0'
													aria-valuemax='100'></div>
												<div
													className='progress-bar bg-theme-2'
													role='progressbar'
													aria-valuenow='20'
													aria-valuemin='0'
													aria-valuemax='100'></div>
											</div>

											<table className='table table-sm table-borderless'>
												<tbody>
													<tr>
														<td className='p-0 pb-1 w-10'>
															<span className='log-indicator border-theme-1 align-middle'></span>
														</td>
														<td className='p-0 pb-1'>
															<span className='font-weight-medium text-muted text-small'>24 Closed</span>
														</td>
													</tr>
													<tr>
														<td className='p-0 pb-1 w-10'>
															<span className='log-indicator border-theme-2 align-middle'></span>
														</td>
														<td className='p-0 pb-1'>
															<span className='font-weight-medium text-muted text-small'>6 Active</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default TaskDetail;

/** @format */

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import App from './App';

const Router = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path='/' component={App} />
			<Route exact path='/tasks/:taskId' component={App} />
			<Route exact path='/chat/' component={App} />
		</Switch>
	</BrowserRouter>
);

export default Router;

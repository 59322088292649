/** @format */

import React from 'react';
import Task from './Task';

class Tasks extends React.Component {
	render() {
		const size = Object.keys(this.props.tasks).length;
		return (
			<React.Fragment>
				<div className='list disable-text-selection' data-check-all='checkAll'>
					{size === 0 && <p>You have no tasks.</p>}
					{Object.keys(this.props.tasks).map((key) => (
						<Task
							key={key} /* <= use this one to save to local stack */
							/* details={this.props.tasks[key]} */ details={this.props.tasks[key].task}
							taskId={this.props.tasks[key].key}
							index={key}
							deleteTask={this.props.deleteTask}
							completeTask={this.props.completeTask}
							markTask={this.props.markTask}>
							{key}
						</Task>
					))}
				</div>
			</React.Fragment>
		);
	}
}

export default Tasks;

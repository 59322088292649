/** @format */

import React from 'react';
import $ from 'jquery';

class StatusCategory extends React.Component {
	checkedRef = React.createRef();
	getCategoryCounts(category) {
		var count = 0;
		Object.keys(this.props.tasks).filter((task) => {
			if (this.props.tasks[task].task.category == category) {
				count++;
			}
		});
		return count;
	}

	showHideCategories = (aclass, bool) => {
		let tasks = document.querySelectorAll('.' + aclass);

		if (bool) {
			for (var index = 0; index < tasks.length; index++) {
				/* tasks[index].classList.remove('hide-task');
                tasks[index].classList.add('show-task'); */
				$(tasks[index]).fadeIn('slow', function () {
					// Animation complete.
				});
			}
		} else {
			for (var index = 0; index < tasks.length; index++) {
				/* tasks[index].classList.remove('show-task');
                tasks[index].classList.add('hide-task'); */
				$(tasks[index]).fadeOut('slow', function () {
					// Animation complete.
				});
			}
		}
	};

	checkCategoryToggles = () => {
		let taskcheckboxes = document.querySelectorAll('.cat-toggle');
		for (var index = 0; index < taskcheckboxes.length; index++) {
			this.showHideCategories(taskcheckboxes[index].id, taskcheckboxes[index].checked);
		}
	};

	render() {
		const categoryname = this.props.categoryName;

		return (
			<React.Fragment>
				<li>
					<div className='custom-control custom-checkbox mb-2' onChange={(e) => this.checkCategoryToggles()}>
						<input
							ref={this.checkedRef}
							type='checkbox'
							defaultChecked
							className='custom-control-input cat-toggle'
							id={categoryname}
						/>
						<label className='custom-control-label' htmlFor={categoryname}>
							{categoryname}
						</label>
						<span className='float-right'>{this.getCategoryCounts(categoryname)}</span>
					</div>
				</li>
			</React.Fragment>
		);
	}
}

export default StatusCategory;

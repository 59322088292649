/** @format */

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Label from './Label';
import Category from './Category';
import $ from 'jquery';

class AddTaskMenu extends React.Component {
	state = {
		startDate: new Date(),
	};

	titleRef = React.createRef();
	dateRef = React.createRef();
	detailsRef = React.createRef();
	categoryRef = React.createRef();
	labelsRef = React.createRef();
	statusRef = React.createRef();
	priorityRef = React.createRef();
	addLabelRef = React.createRef();
	addCategoryRef = React.createRef();

	createTask = (event) => {
		event.preventDefault();

		let tasklabels = document.querySelectorAll('.select2-selection__choice');
		const labelsArray = [];
		for (var index = 0; index < tasklabels.length; index++) {
			labelsArray.push(tasklabels[index].title);
		}

		const task = {
			title: this.titleRef.current.value,
			date: this.state.startDate.toUTCString(),
			dateString: this.state.startDate.toDateString(),
			details: this.detailsRef.current.value,
			category: this.categoryRef.current.value,
			priority: this.priorityRef.current.value,
			labels: this.labelsRef.current.value,
			status: this.statusRef.current.checked,
			labels: labelsArray,
		};

		if (this.titleRef.current.value !== '' && this.detailsRef.current.value.length > 0) {
			this.props.addTask(task);

			$('[data-dismiss=modal]').trigger({ type: 'click' });
			// this clears the form onSubmit.
			event.currentTarget.reset();

			let tasklabels = document.querySelectorAll('.select2-selection__choice');
			for (var index = 0; index < tasklabels.length; index++) {
				var element = tasklabels[index];
				element.parentNode.removeChild(element);
			}
			setTimeout(() => {
				document.getElementById('addTaskForm').classList.remove('was-validated');

				$('#categorySelect option:selected').val('sweetlegs');
			}, 500);
		}
	};

	clearLabels = () => {
		let tasklabels = document.querySelectorAll('.select2-selection__choice');
		for (var index = 0; index < tasklabels.length; index++) {
			var element = tasklabels[index];
			element.parentNode.removeChild(element);
		}
		const addform = document.getElementById('addTaskForm');
		addform.classList.remove('was-validated');
	};

	handleChange = (date) => {
		this.setState({
			startDate: date,
		});
	};

	render() {
		return (
			<React.Fragment>
				<div
					className='modal fade modal-right'
					id='exampleModal'
					tabIndex='-1'
					role='dialog'
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'>
					<div className='modal-dialog' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title' id='exampleModalLabel'>
									Add New Task
								</h5>
								<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<form
									onSubmit={this.createTask}
									className='needs-validation tooltip-label-top'
									id='addTaskForm'
									noValidate>
									<div className='form-group position-relative error-l-50'>
										<label>Title (required)</label>
										<input type='text' ref={this.titleRef} className='form-control' placeholder='' required />
										<div className='invalid-tooltip'>title is required!</div>
									</div>
									<div className='form-group'>
										<label>Due Date:</label>
										<div style={{ display: 'block' }}>
											{/* https://reactdatepicker.com/ */}
											<DatePicker
												ref={this.dateRef}
												className='hasDatepicker form-control'
												selected={this.state.startDate}
												onChange={this.handleChange}
												showTimeSelect
												timeFormat='HH:mm'
												timeIntervals={15}
												timeCaption='time'
												dateFormat='MMMM d, yyyy h:mm aa'
											/>
											{/* <DatePicker
												ref={this.dateRef}
												className='hasDatepicker form-control'
												selected={this.state.startDate}
												onChange={this.handleChange}
											/> */}
										</div>
									</div>
									<div className='form-group position-relative error-l-50'>
										<label>Details (required)</label>
										<textarea
											ref={this.detailsRef}
											className='form-control'
											rows='2'
											placeholder=''
											required></textarea>
										<div className='invalid-tooltip'>Details are required!</div>
									</div>

									<div className='form-group'>
										<label>Category</label>
										<label style={{ float: 'right' }}>
											<a
												style={{ cursor: 'pointer' }}
												onClick={(e) => this.props.createCategory(this.addCategoryRef.current.value)}>
												Add New +
											</a>
										</label>
										<input
											type='text'
											ref={this.addCategoryRef}
											className='form-control'
											placeholder='Add new category'
										/>
										<select
											ref={this.categoryRef}
											className='form-control select2-single'
											data-width='100%'
											id='categorySelect'>
											<option defaultValue value=''>
												Select a Category
											</option>
											{/* <option label='&nbsp;'>&nbsp;</option> */}
											{Object.keys(this.props.categories).map((key) => (
												<Category key={key} categoryName={this.props.categories[key].category}>
													{key}
												</Category>
											))}
											{/* <option value='Flexbox'>Flexbox</option>
											<option value='Sass'>Sass</option>
											<option value='React'>React</option> */}
										</select>
									</div>

									<div className='form-group'>
										<label>Labels</label>
										<label style={{ float: 'right' }}>
											<a
												style={{ cursor: 'pointer' }}
												onClick={(e) => this.props.createLabel(this.addLabelRef.current.value)}>
												Add New +
											</a>
										</label>
										<input type='text' ref={this.addLabelRef} className='form-control' placeholder='Add new label' />
										<select
											ref={this.labelsRef}
											className='form-control select2-multiple'
											multiple='multiple'
											data-width='100%'>
											{Object.keys(this.props.labels).map((key) => (
												<Label key={key} labelName={this.props.labels[key].label}>
													{key}
												</Label>
											))}
											{/* <option value='New Framework'>New Framework</option>
											<option value='Education'>Education</option>
											<option value='Personal'>Personal</option>
											<option value='Code'>Code</option> */}
										</select>
									</div>
									<div className='form-group'>
										<label>Priority</label>
										<select
											ref={this.priorityRef}
											className='form-control select2-single'
											data-width='100%'
											id='prioritySelect'>
											{/* <option label='&nbsp;'>&nbsp;</option> */}
											<option defaultValue value=''>
												Select a Priority
											</option>
											<option value='low'>Low</option>
											<option value='medium'>Medium</option>
											<option value='high'>High</option>
										</select>
									</div>
									<div className='form-group'>
										<label>Status</label>
										<div className='custom-control custom-checkbox'>
											<input ref={this.statusRef} type='checkbox' className='custom-control-input' id='customCheck1' />
											<label className='custom-control-label' htmlFor='customCheck1'>
												Completed
											</label>
										</div>
									</div>
									<div className='modal-footer'>
										<button
											type='button'
											className='btn btn-outline-primary'
											data-dismiss='modal'
											onClick={(e) => this.clearLabels()}>
											Cancel
										</button>
										<button className='btn btn-primary' data-target='#exampleModal' type='submit'>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AddTaskMenu;

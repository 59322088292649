/** @format */

import React from 'react';
import AddTaskMenu from './AddTaskMenu';
import StatusMenu from './StatusMenu';
import Tasks from './Tasks';
import TaskDetail from './TaskDetail';
import $ from 'jquery';
import CompletedTasks from './CompletedTasks';
import base from '../base';

class App extends React.Component {
	checkedRef = React.createRef();
	state = {
		tasks: {},
		completedTasks: {},
		selectedTasks: 0,
		labels: {
			label0: {
				label: 'Code',
			},
			label1: {
				label: 'Reminder',
			},
			label2: {
				label: 'Todo',
			},
		},
		categories: {
			label0: {
				category: 'Flexbox',
			},
			label1: {
				category: 'Sass',
			},
			label2: {
				category: 'React',
			},
			label3: {
				category: 'jQuery',
			},
			label4: {
				category: 'Javascript',
			},
		},
		startDate: new Date(),
	};

	/*  */
	addTask = (task) => {
		/* const tasks = { ...this.state.tasks };
		tasks[`task${Date.now()}`] = task;
		this.setState({
			tasks: tasks,
		}); */

		base.push(`tasks`, {
			data: { task },
			then(err) {
				if (!err) {
				} else {
					console.log(err);
				}
			},
		});
		setTimeout(() => {
			this.getTasks();
			this.getCompletedTasks();
		}, 500);
	};

	/* delete individual task (from within task) */
	deleteTask = (taskId) => {
		const tasks = { ...this.state.tasks };
		const firebasekey = tasks[taskId].key;
		// remove task from Firebase db
		base.remove(`tasks/${firebasekey}`, function (err) {
			if (!err) {
			}
		});
		// remove task from State
		delete tasks[taskId];
		// update State
		this.setState({
			tasks: tasks,
		});
	};

	/* delete multiple tasks (from action menu) */
	deleteTasks = () => {
		const tasks = { ...this.state.tasks };
		Object.keys(tasks).map((key) => {
			if (tasks[key].task.status) {
				const firebasekey = tasks[key].key;
				// remove task from Firebase db
				base.remove(`tasks/${firebasekey}`, function (err) {
					if (!err) {
					}
				});
				// remove task from State
				delete tasks[key];
				// update State
				this.setState({
					tasks: tasks,
				});
			}
		});
	};

	/* complete individual task  */
	completeTask = (taskId) => {
		const tasks = { ...this.state.tasks };
		const completedTasks = { ...this.state.completedTasks };
		const firebasekey = tasks[taskId].key;
		const task = tasks[taskId].task;

		base.push(`completedtasks`, {
			data: { task },
			then(err) {
				if (!err) {
				} else {
					console.log(err);
				}
			},
		});
		base.remove(`tasks/${firebasekey}`, function (err) {
			if (!err) {
			}
		});

		completedTasks[taskId] = tasks[taskId];
		delete tasks[taskId];
		this.setState({
			completedTasks: completedTasks,
			tasks: tasks,
		});
		setTimeout(() => {
			this.getTasks();
			this.getCompletedTasks();
		}, 500);
	};

	/* complete multiple tasks (from action menu) */
	completeTasks = () => {
		const tasks = { ...this.state.tasks };
		const completedTasks = { ...this.state.completedTasks };
		Object.keys(tasks).map((key) => {
			if (tasks[key].task.status) {
				completedTasks[key] = tasks[key];
				const firebasekey = completedTasks[key].key;
				const task = tasks[key].task;

				base.push(`completedtasks`, {
					data: { task },
					then(err) {
						if (!err) {
						} else {
							console.log(err);
						}
					},
				});
				base.remove(`tasks/${firebasekey}`, function (err) {
					if (!err) {
					}
				});
				completedTasks[key] = tasks[key];
				delete tasks[key];

				this.setState({
					completedTasks: completedTasks,
					tasks: tasks,
				});
				setTimeout(() => {
					this.getTasks();
					this.getCompletedTasks();
				}, 500);
			}
		});
	};

	/* expose the tasks checkbox value to the edit menu */
	markTask = (taskId, isChecked) => {
		const tasks = { ...this.state.tasks };
		const taskSize = Object.keys(this.state.tasks).length;

		if (isChecked) {
			tasks[taskId].task.status = true;
			this.state.selectedTasks++;
		} else {
			tasks[taskId].task.status = false;
			this.state.selectedTasks--;
		}
		this.setState({
			tasks: tasks,
		});
		console.log('there are ' + taskSize + ' tasks. there are ' + this.state.selectedTasks + ' selected');
		if (this.state.selectedTasks == taskSize) {
			console.log('all are selected!');
			this.checkedRef.current.checked = true;
		} else {
			this.checkedRef.current.checked = false;
		}
	};

	/* add a new label in the db */
	createLabel = (label) => {
		if (label !== '') {
			base.push(`labels`, {
				data: { label },
				then(err) {
					if (!err) {
					} else {
						console.log(err);
					}
				},
			});
			this.getLabels();
		}
	};

	/* add a new category in the db */
	createCategory = (category) => {
		if (category !== '') {
			base.push(`categories`, {
				data: { category },
				then(err) {
					if (!err) {
					} else {
						console.log(err);
					}
				},
			});
			this.getCategories();
		}
	};

	/* get the tasks lists from the db */
	getTasks = () => {
		base.fetch(`tasks`, {
			context: this,
			asArray: true,
			then(data) {
				this.setState({
					tasks: data,
				});
			},
		});
	};

	getCompletedTasks = () => {
		base.fetch(`completedtasks`, {
			context: this,
			asArray: true,
			then(data) {
				this.setState({
					completedTasks: data,
				});
				//console.log('COMPLETED TASKS: ' + data);
			},
		});
	};
	/* get the labels list from the db */
	getLabels = () => {
		base.fetch(`labels`, {
			context: this,
			asArray: true,
			then(data) {
				this.setState({
					labels: data,
				});
				//console.log('LABELS: ' + data);
			},
		});
	};

	/* get the categories list from the db */
	getCategories = () => {
		base.fetch(`categories`, {
			context: this,
			asArray: true,
			then(data) {
				this.setState({
					categories: data,
				});
				//console.log('CATEGORIES: ' + data);
			},
		});
	};

	/* select all notes to apply choice fro the action menu */
	selectAll = () => {
		const tasks = { ...this.state.tasks };
		Object.keys(tasks).map((key) => {
			tasks[key].task.status = !tasks[key].task.status;
			if (tasks[key].task.status) {
				this.state.selectedTasks++;
			} else {
				this.state.selectedTasks--;
			}
		});
		this.setState({
			tasks: tasks,
		});
	};

	/* getCheckedTaskCount = () => {
		const tasks = { ...this.state.tasks };
		

		Object.keys(tasks).map((key) => {
			
			if (tasks[key].task.status) {
				this.state.selectedTasks++;

			} else {
				this.state.selectedTasks--;
			}
		});
		

		this.setState({
			tasks: tasks,
		});
		
	}; */

	/* set the new date */
	handleChange = (date) => {
		this.setState({
			startDate: date,
		});
	};

	componentWillMount() {}

	componentDidMount() {
		setTimeout(() => {
			this.getLabels();
			this.getCategories();
			this.getTasks();
			this.getCompletedTasks();
			console.log('MOUNTED!');
		}, 500);
		setTimeout(() => {}, 0);
	}

	render() {
		const taskSize = Object.keys(this.state.tasks).length;
		const completedTaskSize = Object.keys(this.state.completedTasks).length;

		if (this.props.match.path === '/chat/') {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='container-fluid'>
						<div className='row app-row'>
							<div className='col-12 chat-app'>
								<div className='d-flex flex-row justify-content-between mb-3 chat-heading-container'>
									<div className='d-flex flex-row chat-heading'>
										<a className='d-flex' href='#'>
											<img
												alt='Profile Picture'
												src='/img/profile-pic-l.jpg'
												className='img-thumbnail border-0 rounded-circle ml-0 mr-4 list-thumbnail align-self-center small'
											/>
										</a>
										<div className=' d-flex min-width-zero'>
											<div className='card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
												<div className='min-width-zero'>
													<a href='#'>
														<p className='list-item-heading mb-1 truncate '>Sarah Kortney</p>
													</a>
													<p className='mb-0 text-muted text-small'>Last seen today 01:24</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='separator mb-5'></div>

								<div className='scroll'>
									<div className='scroll-content'>
										<div className='card d-inline-block mb-3 float-left mr-2'>
											<div className='position-absolute pt-1 pr-2 r-0'>
												<span className='text-extra-small text-muted'>09:25</span>
											</div>
											<div className='card-body'>
												<div className='d-flex flex-row pb-2'>
													<a className='d-flex' href='#'>
														<img
															alt='Profile Picture'
															src='/img/profile-pic-l.jpg'
															className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
														/>
													</a>
													<div className=' d-flex flex-grow-1 min-width-zero'>
														<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
															<div className='min-width-zero'>
																<p className='mb-0 truncate list-item-heading'>Sarah Kortney</p>
															</div>
														</div>
													</div>
												</div>

												<div className='chat-text-left'>
													<p className='mb-0 text-semi-muted'>
														What do you think about our plans for this product launch?
													</p>
												</div>
											</div>
										</div>
										<div className='clearfix'></div>
										<div className='card d-inline-block mb-3 float-left mr-2'>
											<div className='position-absolute pt-1 pr-2 r-0'>
												<span className='text-extra-small text-muted'>09:28</span>
											</div>
											<div className='card-body'>
												<div className='d-flex flex-row pb-2'>
													<a className='d-flex' href='#'>
														<img
															alt='Profile Picture'
															src='/img/profile-pic-l.jpg'
															className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
														/>
													</a>
													<div className=' d-flex flex-grow-1 min-width-zero'>
														<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
															<div className='min-width-zero'>
																<p className='mb-0 truncate list-item-heading'>Sarah Kortney</p>
															</div>
														</div>
													</div>
												</div>

												<div className='chat-text-left'>
													<p className='mb-0 text-semi-muted'>
														It looks to me like you have a lot planned before your deadline. I would suggest you push
														your deadline back so you have time to run a successful advertising campaign.
													</p>
												</div>
											</div>
										</div>
										<div className='clearfix'></div>
										<div className='card d-inline-block mb-3 float-left mr-2'>
											<div className='position-absolute pt-1 pr-2 r-0'>
												<span className='text-extra-small text-muted'>09:30</span>
											</div>
											<div className='card-body'>
												<div className='d-flex flex-row pb-2'>
													<a className='d-flex' href='#'>
														<img
															alt='Profile Picture'
															src='/img/profile-pic-l.jpg'
															className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
														/>
													</a>
													<div className='d-flex flex-grow-1 min-width-zero'>
														<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
															<div className='min-width-zero'>
																<p className='mb-0 truncate list-item-heading'>Sarah Kortney</p>
															</div>
														</div>
													</div>
												</div>

												<div className='chat-text-left'>
													<p className='mb-0 text-semi-muted'>
														How do you think we should move forward with this project? As you know, we are expected to
														present it to our clients next week.{' '}
													</p>
												</div>
											</div>
										</div>
										<div className='clearfix'></div>
										<div className='card d-inline-block mb-3 float-right  mr-2'>
											<div className='position-absolute pt-1 pr-2 r-0'>
												<span className='text-extra-small text-muted'>09:41</span>
											</div>
											<div className='card-body'>
												<div className='d-flex flex-row pb-2'>
													<a className='d-flex' href='#'>
														<img
															alt='Profile Picture'
															src='/img/profile-pic-l-4.jpg'
															className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
														/>
													</a>
													<div className='d-flex flex-grow-1 min-width-zero'>
														<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
															<div className='min-width-zero'>
																<p className='mb-0 list-item-heading truncate'>Mimi Carreira</p>
															</div>
														</div>
													</div>
												</div>

												<div className='chat-text-left'>
													<p className='mb-0 text-semi-muted'>
														I would suggest you discuss this further with the advertising team.
													</p>
												</div>
											</div>
										</div>
										<div className='clearfix'></div>
										<div className='card d-inline-block mb-3 float-right  mr-2'>
											<div className='position-absolute pt-1 pr-2 r-0'>
												<span className='text-extra-small text-muted'>09:41</span>
											</div>
											<div className='card-body'>
												<div className='d-flex flex-row pb-2'>
													<a className='d-flex' href='#'>
														<img
															alt='Profile Picture'
															src='/img/profile-pic-l-4.jpg'
															className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
														/>
													</a>
													<div className='d-flex flex-grow-1 min-width-zero'>
														<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
															<div className='min-width-zero'>
																<p className='mb-0 list-item-heading truncate'>Mimi Carreira</p>
															</div>
														</div>
													</div>
												</div>

												<div className='chat-text-left'>
													<p className='mb-0 text-semi-muted'>
														I am very busy at the moment and on top of everything, I forgot my umbrella today.
													</p>
												</div>
											</div>
										</div>
										<div className='clearfix'></div>
										<div className='card d-inline-block mb-3 float-right mr-2'>
											<div className='position-absolute pt-1 pr-2 r-0'>
												<span className='text-extra-small text-muted'>09:41</span>
											</div>
											<div className='card-body'>
												<div className='d-flex flex-row pb-2'>
													<a className='d-flex' href='#'>
														<img
															alt='Profile Picture'
															src='/img/profile-pic-l-4.jpg'
															className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
														/>
													</a>
													<div className='d-flex flex-grow-1 min-width-zero'>
														<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
															<div className='min-width-zero'>
																<p className='mb-0 list-item-heading truncate'>Mimi Carreira</p>
															</div>
														</div>
													</div>
												</div>

												<div className='chat-text-left'>
													<p className='mb-0 text-semi-muted'>
														I am very busy at the moment and on top of everything, I forgot my umbrella today.
													</p>
												</div>
											</div>
										</div>
										<div className='clearfix'></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='app-menu'>
						<ul className='nav nav-tabs card-header-tabs ml-0 mr-0 mb-1' role='tablist'>
							<li className='nav-item w-50 text-center'>
								<a
									className='nav-link active'
									id='first-tab'
									data-toggle='tab'
									href='#firstFull'
									role='tab'
									aria-selected='true'>
									Messages
								</a>
							</li>
							<li className='nav-item w-50 text-center'>
								<a
									className='nav-link'
									id='second-tab'
									data-toggle='tab'
									href='#secondFull'
									role='tab'
									aria-selected='false'>
									Contacts
								</a>
							</li>
						</ul>

						<div className='p-4 h-100'>
							<div className='form-group'>
								<input type='text' className='form-control rounded' placeholder='Search' />
							</div>
							<div className='tab-content h-100'>
								<div
									className='tab-pane fade show active  h-100'
									id='firstFull'
									role='tabpanel'
									aria-labelledby='first-tab'>
									<div className='scroll'>
										<div className='d-flex flex-row mb-1 border-bottom pb-3 mb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className=' mb-0 truncate'>Sarah Kortney</p>
														</a>
														<p className='mb-1 text-muted text-small'>14:20</p>
													</div>
												</div>
											</div>
										</div>

										<div className='d-flex flex-row mb-1 border-bottom pb-3 mb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-2.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className=' mb-0 truncate'>Rasheeda Vaquera</p>
														</a>
														<p className='mb-1 text-muted text-small'>11:10</p>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-1 border-bottom pb-3 mb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-3.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className=' mb-0 truncate'>Shelia Otterson</p>
														</a>
														<p className='mb-1 text-muted text-small'>09:50</p>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-1  pb-3 mb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-4.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className=' mb-0 truncate'>Latarsha Gama</p>
														</a>
														<p className='mb-1 text-muted text-small'>09:10</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='tab-pane fade  h-100' id='secondFull' role='tabpanel' aria-labelledby='second-tab'>
									<div className='scroll'>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Sarah Kortney</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-2.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Williemae Lagasse</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-3.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Tommy Nash</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-4.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Mayra Sibley</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-5.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Kathryn Mengel</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-2.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Williemae Lagasse</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-3.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Tommy Nash</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-4.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Mayra Sibley</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-3.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Tommy Nash</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-4.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Mayra Sibley</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-5.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Kathryn Mengel</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-2.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Williemae Lagasse</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 border-bottom pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-3.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Tommy Nash</p>
														</a>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex flex-row mb-3 pb-3'>
											<a className='d-flex' href='#'>
												<img
													alt='Profile Picture'
													src='/img/profile-pic-l-4.jpg'
													className='img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall'
												/>
											</a>
											<div className='d-flex flex-grow-1 min-width-zero'>
												<div className='m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
													<div className='min-width-zero'>
														<a href='#'>
															<p className='mb-0 truncate'>Mayra Sibley</p>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<a className='app-menu-button d-inline-block d-xl-none' href='#'>
							<i className='simple-icon-options'></i>
						</a>
					</div>
					<div className='chat-input-container d-flex justify-content-between align-items-center'>
						<input className='form-control flex-grow-1' type='text' placeholder='Say something...' />
						<div>
							<button type='button' className='btn btn-outline-primary icon-button large'>
								<i className='simple-icon-paper-clip'></i>
							</button>
							<button type='button' className='btn btn-primary icon-button large'>
								<i className='simple-icon-arrow-right'></i>
							</button>
						</div>
					</div>
				</React.Fragment>
			);
		}
		if (this.props.match.params.taskId !== undefined) {
			// Render loading state ...
			return (
				<React.Fragment>
					<div className='container-fluid'>
						<div className='row app-row'>
							<div className='col-12' data-check-all='checkAll'>
								<TaskDetail details={this.state.tasks} task={this.props.match.params.taskId} />
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<div className='container-fluid'>
						<div className='row app-row'>
							<div className='col-12'>
								<div className='mb-2'>
									<h1>
										My Tasks <span style={{ fontSize: '1rem' }}>(pending : {taskSize})</span>
									</h1>

									<div className='top-right-button-container'>
										<button
											type='button'
											className='btn btn-outline-primary btn-lg top-right-button  mr-1'
											data-toggle='modal'
											data-backdrop='static'
											data-target='#exampleModal'>
											ADD NEW
										</button>
										{/* add note modal menu */}
										<AddTaskMenu
											addTask={this.addTask}
											createLabel={this.createLabel}
											createCategory={this.createCategory}
											labels={this.state.labels}
											categories={this.state.categories}
										/>
										{/* end add note modal menu */}
										<div className='btn-group'>
											<div className='btn btn-primary btn-lg pl-4 pr-0 check-button'>
												<label
													className='custom-control custom-checkbox mb-0 d-inline-block'
													onChange={(e) => this.selectAll()}>
													<input type='checkbox' className='custom-control-input' id='' ref={this.checkedRef} />
													<span className='custom-control-label'>&nbsp;</span>
												</label>
											</div>
											<button
												type='button'
												className='btn btn-lg btn-primary dropdown-toggle dropdown-toggle-split'
												data-toggle='dropdown'
												aria-haspopup='true'
												aria-expanded='false'>
												<span className='sr-only'>Toggle Dropdown</span>
											</button>
											<div className='dropdown-menu dropdown-menu-right'>
												<a className='dropdown-item' href='#' onClick={(e) => this.deleteTasks()}>
													<i className='simple-icon-trash heading-icon' style={{ paddingRight: '5px' }}></i>
													Delete Task(s)
												</a>
												<a className='dropdown-item' href='#' onClick={(e) => this.completeTasks()}>
													<i className='simple-icon-check heading-icon' style={{ paddingRight: '5px' }}></i>
													Complete Task(s)
												</a>
											</div>
										</div>
									</div>
								</div>

								<div className='mb-2'>
									<a
										className='btn pt-0 pl-0 d-inline-block d-md-none'
										data-toggle='collapse'
										href='#displayOptions'
										role='button'
										aria-expanded='true'
										aria-controls='displayOptions'>
										Display Options
										<i className='simple-icon-arrow-down align-middle'></i>
									</a>
									<div className='collapse d-md-block' id='displayOptions'>
										<div className='d-block d-md-inline-block'>
											<div className='btn-group float-md-left mr-1 mb-1'>
												<button
													className='btn btn-outline-dark btn-xs dropdown-toggle'
													type='button'
													data-toggle='dropdown'
													aria-haspopup='true'
													aria-expanded='false'>
													Order By
												</button>
												<div className='dropdown-menu'>
													<a className='dropdown-item' href='#'>
														Action
													</a>
													<a className='dropdown-item' href='#'>
														Another action
													</a>
												</div>
											</div>
											<div className='search-sm d-inline-block float-md-left mr-1 mb-1 align-top'>
												<input placeholder='Search...' />
											</div>
										</div>
									</div>
								</div>

								<div className='separator mb-5'></div>

								{/* tasks */}
								<Tasks
									tasks={this.state.tasks}
									deleteTask={this.deleteTask}
									completeTask={this.completeTask}
									markTask={this.markTask}
								/>
								{/* end tasks */}

								{/* completed tasks section */}
								<div className='separator mb-5'></div>
								<div className='mb-2'>
									<h1>
										Completed Tasks <span style={{ fontSize: '1rem' }}>({completedTaskSize})</span>
									</h1>
								</div>

								<div className='mb-2'>
									<a
										className='btn pt-0 pl-0 d-inline-block d-md-none'
										data-toggle='collapse'
										href='#displayOptions'
										role='button'
										aria-expanded='true'
										aria-controls='displayOptions'>
										Display Options
										<i className='simple-icon-arrow-down align-middle'></i>
									</a>
									<div className='collapse d-md-block' id='displayOptions'>
										<div className='d-block d-md-inline-block'>
											<div className='btn-group float-md-left mr-1 mb-1'>
												<button
													className='btn btn-outline-dark btn-xs dropdown-toggle'
													type='button'
													data-toggle='dropdown'
													aria-haspopup='true'
													aria-expanded='false'>
													Order By
												</button>
												<div className='dropdown-menu'>
													<a className='dropdown-item' href='#'>
														Action
													</a>
													<a className='dropdown-item' href='#'>
														Another action
													</a>
												</div>
											</div>
											<div className='search-sm d-inline-block float-md-left mr-1 mb-1 align-top'>
												<input placeholder='Search...' />
											</div>
										</div>
									</div>
								</div>
								{/* end completed tasks section */}
								<div className='separator mb-5'></div>
								<CompletedTasks
									tasks={this.state.tasks}
									completedtasks={this.state.completedTasks}
									deleteTask={this.deleteTask}
									markTask={this.markTask}
								/>
							</div>
						</div>
					</div>
					{/* status menu */}
					<StatusMenu
						tasks={this.state.tasks}
						completedtasks={this.state.completedTasks}
						labels={this.state.labels}
						categories={this.state.categories}
					/>
					{/* end status menu */}
				</React.Fragment>
			);
		}
	}
}

export default App;

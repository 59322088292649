/** @format */

import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
	apiKey: 'AIzaSyB5zVr9bmF1rVWOZG5q7nlr7yr28uVpBQI',
	authDomain: 'tasks-app-e9006.firebaseapp.com',
	databaseURL: 'https://tasks-app-e9006.firebaseio.com',
});

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };
export default base;

/** @format */

import React from 'react';

class Category extends React.Component {
	render() {
		const categoryname = this.props.categoryName;

		return (
			<React.Fragment>
				<option value={categoryname}>{categoryname}</option>
			</React.Fragment>
		);
	}
}

export default Category;

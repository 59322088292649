/** @format */

import React from 'react';

class Task extends React.Component {
	checkedRef = React.createRef();

	nullEvent = () => {};
	goToTask = () => {
		const atask = this.props.taskId;
		this.props.history.push(`/tasks/${atask}`);
	};
	render() {
		const { title, date, dateString, details, category, priority, labels, status, isComplete } = this.props.details;
		const index = this.props.index;
		const taskId = this.props.taskId;
		return (
			<React.Fragment>
				{/* {isComplete == false && ( */}
				<div className={`list disable-text-selection ${category} newest-task`} data-check-all='checkAll'>
					<div className='card d-flex flex-row mb-3'>
						<div className='d-flex flex-grow-1 min-width-zero'>
							<div className='card-body task-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center'>
								<a
									href={`/tasks/${taskId}`}
									/* onClick={(e) => this.goToTask()} */
									className='list-item-heading mb-0 truncate w-20 w-xs-30 mt-0'
									style={{ cursor: 'pointer' }}>
									<i className='simple-icon-note heading-icon'></i>
									<span className='align-middle d-inline-block'>{title}</span>
								</a>

								{priority === 'high' && (
									<a
										title='High Priority'
										className='list-item-heading mb-0 truncate w-5 w-xs-30 mt-0'
										style={{ cursor: 'pointer' }}>
										<i className='simple-icon-fire heading-icon'></i>
									</a>
								)}

								{priority === 'medium' && (
									<a
										title='Medium Priority'
										className='list-item-heading mb-0 truncate w-5 w-xs-10 mt-0'
										style={{ cursor: 'pointer' }}>
										<i className='simple-icon-energy heading-icon'></i>
									</a>
								)}
								{priority === 'low' && (
									<a
										title='Low Priority'
										className='list-item-heading mb-0 truncate w-5 w-xs-10 mt-0'
										style={{ cursor: 'pointer' }}>
										<i className='simple-icon-pin heading-icon'></i>
									</a>
								)}
								<p className='mb-0 text-muted text-small w-15 w-xs-100'>details: {details}</p>
								<p className='mb-0 text-muted text-small w-15 w-xs-100'>category: {category}</p>
								<p className='mb-0 text-muted text-small w-15 w-xs-100'>due: {dateString}</p>
								<div className='w-5 w-xs-100'>
									{status === false && <span className='badge badge-pill badge-secondary'>PENDING</span>}
								</div>
								<div className='w-10 w-xs-100'>
									{status === true && <span className='badge badge-pill badge-secondary'>SELECTED</span>}
								</div>
								<a
									title='Delete this task.'
									className='list-item-heading mb-0 truncate w-5 w-xs-100 mt-0'
									style={{ cursor: 'pointer' }}
									onClick={(e) => this.props.deleteTask(index)}>
									<i className='simple-icon-trash heading-icon'></i>
								</a>
								<a
									title='Mark task as complete.'
									className='list-item-heading mb-0 truncate w-5 w-xs-100 mt-0'
									style={{ cursor: 'pointer' }}
									onClick={(e) => this.props.completeTask(index)}>
									<i className='simple-icon-check heading-icon'></i>
								</a>
							</div>
							<label
								className='custom-control custom-checkbox mb-0 align-self-center mr-4 mb-1'
								onClick={(e) => this.props.markTask(index, this.checkedRef.current.checked)}
								onChange={this.nullEvent()}>
								{status === true && (
									<input type='checkbox' defaultChecked ref={this.checkedRef} className='custom-control-input' />
								)}
								{status === false && <input type='checkbox' ref={this.checkedRef} className='custom-control-input' />}
								<span className='custom-control-label'>&nbsp;</span>
							</label>
						</div>
					</div>
				</div>
				{/* )} */}
			</React.Fragment>
		);
	}
}

export default Task;

/** @format */

// let's go!/** @format */

import React from 'react';
import { render } from 'react-dom';
import Router from './components/Router';

import './font/iconsmind-s/css/iconsminds.css';
import './font/simple-line-icons/css/simple-line-icons.css';
import './css/vendor/jquery.contextMenu.min.css';
import './css/vendor/bootstrap.min.css';
import './css/vendor/bootstrap.rtl.only.min.css';
import './css/vendor/perfect-scrollbar.css';
import './css/vendor/select2.min.css';
import './css/vendor/select2-bootstrap.min.css';
import './css/vendor/component-custom-switch.min.css';
import './css/main.css';
import './css/dore.dark.blue.css';
render(<Router />, document.querySelector('#main'));

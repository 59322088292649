/** @format */

import React from 'react';
import StatusLabel from './StatusLabel';
import StatusCategory from './StatusCategory';

class StatusMenu extends React.Component {
	getPriorityCounts(priority) {
		var count = 0;
		Object.keys(this.props.tasks).filter((task) => {
			if (this.props.tasks[task].task.priority == priority) {
				count++;
			}
		});
		return count;
	}

	componentDidMount() {
		setTimeout(() => {}, 1500);
		setTimeout(() => {}, 0);
	}

	render() {
		const taskSize = Object.keys(this.props.tasks).length;

		const completedTaskSize = Object.keys(this.props.completedtasks).length;
		return (
			<React.Fragment>
				<div className='app-menu'>
					<div className='p-4 h-100'>
						<div className='scroll'>
							<p className='text-muted text-small'>Status</p>
							<ul className='list-unstyled mb-5'>
								<li>
									<a href='#'>
										<i className='simple-icon-note'></i>
										Pending Tasks
										<span className='float-right'>{taskSize}</span>
									</a>
								</li>
								<li>
									<a href='#'>
										<i className='simple-icon-emotsmile'></i>
										Completed Tasks
										<span className='float-right'>{completedTaskSize}</span>
									</a>
								</li>
							</ul>
							<p className='text-muted text-small'>Priority</p>
							<ul className='list-unstyled mb-5'>
								<li>
									<a href='#'>
										<i className='simple-icon-pin'></i>
										Low Priority
										<span className='float-right'>{this.getPriorityCounts('low')}</span>
									</a>
								</li>
								<li>
									<a href='#'>
										<i className='simple-icon-energy'></i>
										Medium Priority
										<span className='float-right'>{this.getPriorityCounts('medium')}</span>
									</a>
								</li>
								<li>
									<a href='#'>
										<i className='simple-icon-fire'></i>
										High Priority
										<span className='float-right'>{this.getPriorityCounts('high')}</span>
									</a>
								</li>
							</ul>
							<p className='text-muted text-small'>Categories</p>
							<ul className='list-unstyled mb-5'>
								{Object.keys(this.props.categories).map((key) => (
									<StatusCategory
										key={key}
										categoryName={this.props.categories[key].category}
										showHideCategories={this.showHideCategories}
										tasks={this.props.tasks}>
										{key}
									</StatusCategory>
								))}
							</ul>

							<p className='text-muted text-small'>Labels</p>
							<div>
								{Object.keys(this.props.labels).map((key) => (
									<StatusLabel key={key} labelName={this.props.labels[key].label}>
										{key}
									</StatusLabel>
								))}
							</div>
						</div>
					</div>
					<a className='app-menu-button d-inline-block d-xl-none' href='#'>
						<i className='simple-icon-options'></i>
					</a>
				</div>
			</React.Fragment>
		);
	}
}

export default StatusMenu;

/** @format */

import React from 'react';

class StatusLabel extends React.Component {
	render() {
		const labelname = this.props.labelName;

		return (
			<React.Fragment>
				<p className='d-sm-inline-block mb-1'>
					<a href='#'>
						<span className='badge badge-pill badge-outline-primary mb-1'>{labelname}</span>
					</a>
				</p>
			</React.Fragment>
		);
	}
}

export default StatusLabel;

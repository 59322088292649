/** @format */

import React from 'react';

class Label extends React.Component {
	render() {
		const labelname = this.props.labelName;

		return (
			<React.Fragment>
				<option className='task-label' value={labelname}>
					{labelname}
				</option>
			</React.Fragment>
		);
	}
}

export default Label;

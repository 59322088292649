/** @format */

import React from 'react';
import CompletedTask from './CompletedTask';

class CompletedTasks extends React.Component {
	render() {
		const size = Object.keys(this.props.completedtasks).length;
		return (
			<React.Fragment>
				<div className='list disable-text-selection' data-check-all='checkAll'>
					{size === 0 && <p>You have no completed tasks.</p>}
					{Object.keys(this.props.completedtasks).map((key) => (
						<CompletedTask
							key={key}
							cdetails={this.props.completedtasks[key].task}
							index={key}
							deleteTask={this.props.deleteTask}
							markTask={this.props.markTask}>
							{key}
						</CompletedTask>
					))}
				</div>
			</React.Fragment>
		);
	}
}

export default CompletedTasks;

/** @format */

import React from 'react';

class CompletedTask extends React.Component {
	checkedRef = React.createRef();

	nullEvent = () => {};
	render() {
		const { title, date, dateString, details, category, labels, status } = this.props.cdetails;
		const index = this.props.index;
		return (
			<React.Fragment>
				<div className='list disable-text-selection' data-check-all='checkAll'>
					<div className='card d-flex flex-row mb-3'>
						<div className='d-flex flex-grow-1 min-width-zero'>
							<div className='card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center'>
								<a className='list-item-heading mb-0 truncate w-10 w-xs-100 mt-0' style={{ cursor: 'pointer' }}>
									<i className='simple-icon-emotsmile heading-icon'></i>
									<span className='align-middle d-inline-block'>{title}</span>
								</a>
								<p className='mb-0 text-muted text-small w-15 w-xs-100'>{details}</p>
								<p className='mb-0 text-muted text-small w-15 w-xs-100'>{category}</p>
								<p className='mb-0 text-muted text-small w-15 w-xs-100'>{dateString}</p>
								<div className='w-15 w-xs-100'>
									<span className='badge badge-pill badge-secondary'>COMPLETE</span>
								</div>
								<a
									title='Mark Task Uncompleted'
									className='list-item-heading mb-0 truncate w-10 w-xs-100 mt-0'
									style={{ cursor: 'pointer' }}
									onClick={(e) => this.props.deleteTask(index)}>
									<i className='simple-icon-action-undo heading-icon'></i>
								</a>
							</div>
							<label
								className='custom-control custom-checkbox mb-0 align-self-center mr-4 mb-1'
								onClick={(e) => this.props.markTask(index, this.checkedRef.current.checked)}
								onChange={this.nullEvent()}>
								{status === true && (
									<input type='checkbox' defaultChecked ref={this.checkedRef} className='custom-control-input' />
								)}
								{status === false && <input type='checkbox' ref={this.checkedRef} className='custom-control-input' />}
								<span className='custom-control-label'>&nbsp;</span>
							</label>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default CompletedTask;
